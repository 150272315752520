.register {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1rem;
	border: 1px solid lightgrey;
	text-align: center;
	width: 90%;
	max-width: 30rem;
}

.register input {
	width: 90%;
	padding: 0.5rem;
	margin: 0.5rem;
	border: none;
	border-bottom: 2px solid lightgrey;
}

.register input[type='submit'] {
	border: none;
	cursor: pointer;
	padding: 0.75rem;
	transition: background 0.2s ease;
}

.register input[type='submit']:hover {
	background: none;
}

.register__accountHolder > * {
	margin: 0.5rem;
}

.register__accountHolder > a {
	text-decoration: none;
	color: tomato;
	display: inline-block;
}
.register__accountHolder > a:hover {
	text-decoration: underline;
}
