.App {
	background-color: white;
	min-height: 100vh;
	color: #282c34;
	padding: 1rem;
	/* max-width: 30rem; */
	width: 30rem;
	margin: 0 auto;
}

.App-link {
	color: #61dafb;
}

.app__addPost {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 7rem;
	padding: 0.25rem;
	cursor: pointer;
	background: lightgrey;
	color: black;
}

.nav {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: black;
	margin: 1rem 0;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	border: 1px solid lightgrey;
	transition: background 0.2s ease;
}

.nav:hover {
	background: lightgray;
}
