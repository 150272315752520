.modal {
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: all 1s ease;
	z-index: 99;
}

.modal.show {
	opacity: 1;
}

.modal__content {
	width: 22rem;
	padding: 1rem;
	background: white;
	border-radius: 0.5rem;
	transform: scale(0.8);
	transition: all 1s ease;
}

.modal.show .modal__content {
	transform: scale(1);
}

.modal__mediaPreview {
	margin: 0.5rem;
	max-width: 18rem;
}

.modal input[type='text'],
.modal textarea {
	display: block;
	width: 100%;
	outline: none;
	border: none;
	border-bottom: 2px solid whitesmoke;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal textarea {
	height: 6rem;
	resize: none;
}

::placeholder {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal input[type='submit'] {
	display: inline-block;
	padding: 0.25rem 0.5rem;
	cursor: pointer;
}

.modal__filePicker {
	cursor: pointer;
	margin: 0.25rem;
	color: #999999;
}

.modal__footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.modal form input,
.modal form textarea {
	padding: 0.25rem 0.5rem;
}
