.login {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1rem;
	border: 1px solid lightgrey;
	text-align: center;
	width: 90%;
	max-width: 30rem;
}

.login input,
.login__guest > button {
	width: 90%;
	padding: 0.5rem;
	margin: 0.5rem;
	border: none;
	border-bottom: 2px solid lightgrey;
}

.login input[type='submit'],
.login__guest > button {
	border: none;
	cursor: pointer;
	padding: 0.75rem;
	transition: background 0.2s ease;
}

.login input[type='submit']:hover,
.login__guest > button:hover {
	background: none;
}

.login__newUser > * {
	margin: 0.5rem;
}

.login__newUser > a {
	text-decoration: none;
	color: tomato;
	display: inline-block;
}
.login__newUser > a:hover {
	text-decoration: underline;
}

.login__or {
	margin: 0.5rem;
	text-align: center;
}
